.helperText {
  color: darkGrey;
  margin-bottom: 5px;
}

.closeButton {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer !important;
}

.section {
  display: flex;
  flex-direction: column;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.modalContent {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.saveButton {
  align-self: end;
}

