@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i');
@import url('https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700');
@import url('https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i');

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_Light.ttf) format("truetype");
  font-weight: 300;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_LightItalic.ttf) format("truetype");
  font-weight: 300;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_Regular.ttf) format("truetype");
  font-weight: 400;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_RegularItalic.ttf) format("truetype");
  font-weight: 400;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_Medium.ttf) format("truetype");
  font-weight: 500;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_MediumItalic.ttf) format("truetype");
  font-weight: 500;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_Bold.ttf) format("truetype");
  font-weight: 700;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_BoldItalic.ttf) format("truetype");
  font-weight: 700;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_Heavy.ttf) format("truetype");
  font-weight: 800;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(fonts/Tuna/Tuna_HeavyItalic.ttf) format("truetype");
  font-weight: 800;
	font-style: italic;
}
