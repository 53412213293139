.commentariesEdit {
}

.commentariesEdit__activeFragment {
  font-size: 1.125rem;
}

.commentariesEdit__activeFragment--isDraft,
.commentariesEdit__activeFragment--isPublished {
  padding: 0.625rem 0.3125rem;
  border-radius: 0.1875rem;
  color: #fff;
}

.commentariesEdit__infoHeader {
  flex-basis: 100%;
  display: flex;
  margin-bottom: 1.875rem;
}

.commentariesEdit__infoHeader--isCollapsed {
  display: none;
}

.commentariesEdit__notice {
  padding: 0.625rem;
  color: #fff;
}

.commentariesEdit__activeFragment--isPublished,
.commentariesEdit__notice--isPublished {
  background: #c3423f;
}

.commentariesEdit__activeFragment--isDraft,
.commentariesEdit__notice--isDraft {
  background: #6daedb;
}

.commentariesEdit__createAsCopyAction {
  display: inline-block;
  padding: 0.3125rem 0.5625rem;
  background: #fff;
  border: none;
  border-radius: 0.125rem;
  color: #9e2b25;
  font-weight: 700;
}

.commentariesEdit__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.commentariesEdit__xopusIframe {
  border: none;
  height: calc(100vh - 6.25rem);
  background: #fff;
  flex: 2;
}

.commentariesEdit__asideRight {
  margin-left: 1.25rem;
  max-width: 25rem;
  height: calc(100vh - 13.125rem);
  padding: 0.625rem 0.625rem 5.625rem 0.625rem;
  background: rgba(103, 89, 123, 0.1);
  border-radius: 0.1875rem;
  overflow: hidden;
}

.commentariesEdit__versionsInfo .commentMeta__title {
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.commentariesEdit__versionsInfo .commentMeta__draft {
  margin-bottom: 1.875rem;
}

.commentariesEdit__versionsInfo {
  height: 100%;
  overflow: scroll;
}

.commentariesEdit__versionsInfo p {
  margin: 0.3125rem 0;
  font-size: 0.875rem;
}

.commentariesEdit__versionsInfo ul {
  list-style: none;
  padding: 0 0 5.625rem 0;
}

.commentariesEdit__versionsInfo li:not(:first-child) {
  padding: 0.625rem 0;
}

.commentariesEdit__versionsInfo li:first-child {
  padding-top: 0;
}
