.SlatePopper {
  z-index: 1000;
  margin-bottom: 7px;
}

.SlatePopperArrow {
  position: absolute;
  width: 3em;
  height: 3em;

  /* "top" placement */
  left: 0;
  width: 3em;
  bottom: 0;
  height: 1em;
  margin-bottom: -0.9em;
}

.SlatePopperArrow::before {
  content: "";
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;

  /* "top" placement */
  border-width: 0.5em 0.5em 0 0.5em;
  border-color: #fff transparent transparent transparent;
}

.SlatePopperPaper {
  width: 700px;
}

.SlatePopperContent {
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
