button {
  border: none;
  background-color: #F5F5F5;
  padding: 2px 4px;
  border-radius: 2px;
  text-decoration: underline dotted;
  display: inline-flex;
  align-items: baseline;
  gap: 4px;
  font-size: inherit;
  cursor: text;
}