.fileDropzone__dropzone {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fff;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.fileDropzone__error {
  margin: 16px 16px -16px;
  padding: 20px;
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(182, 24, 39, 0.5);
  border: 1px solid rgba(182, 24, 39, 0.5);
  border-radius: 2px;
  color: #fff;
}
