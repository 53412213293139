.commentMeta ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.commentMeta li {
  padding: 0.625rem 0;
}

.commentMeta li:not(:last-child) {
  padding: 0.625rem 0;
  border-bottom: 0.0625rem solid #fff;
}

.commentMeta__statusIndicator {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
}

.commentMeta__statusIndicator:not(:last-child) {
  margin-right: 0.1875rem;
}

.commentMeta__statusIndicator--isDraft {
  background: #f8e74e;
}

.commentMeta__statusIndicator--isPublished {
  background: #98f398;
}

.commentMeta__statusIndicator--isOld {
  background: #8a8a8a;
}
