.newsArticle,
.embeddedDocument {
  font-family: var(--font-family-sans);
  background-color: white;
  color: var(--charcoal-grey);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.newsArticle a,
.embeddedDocument a {
  color: var(--darkish-blue);
}

/* This is for matching "normalized" css in juridika embeddedDocument */
.embeddedDocument .leadParagraph {
  font-family: var(--font-family-display);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.031rem;
}

/* This is for matching "normalized" css in juridika for newsArticle */
@media only screen and (min-width: 1024px) {
  .newsArticle .juridikaInsightHeader,
  .newsArticle .serialHeader {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle .juridikaInsightHeader,
  .newsArticle .serialHeader {
    width: 50rem;
  }
}

.newsArticle {
  max-width: 71.25rem;
}

.newsArticle .byline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1rem;
  margin: 0 0 1rem;
  border-top: 0.0625rem solid #c2c0c2;
  border-bottom: 0.0625rem solid #c2c0c2;
  padding: 0.625rem 0;
  color: #5c5b5f;
}

.newsArticle .byline cite:before {
  content: '\A0';
}

.newsArticle .byline__contributorInfo {
  margin-right: 0.625rem;
}

.newsArticle .byline__contributorInfo a {
  text-decoration: none;
}

.newsArticle .byline__contributorInfo cite:before {
  content: '';
}

.newsArticle .byline__contributorInfo cite {
  font-style: normal;
}

.newsArticle .byline__publicationDate .icon-dividerDot {
  margin: 0 0.625rem 0 0;
  vertical-align: middle;
}

.newsArticle .icon-dividerDot {
  margin: 0 0.625rem;
  vertical-align: middle;
}

@media only screen and (min-width: 768px) {
  .newsArticle .bylineContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
}

.newsArticle .bylineContent .contributors {
  margin: 0;
  padding: 0;
  list-style: none;
}

.newsArticle .bylineContent .contributor {
  margin: 0;
}

.newsArticle .bylineContent .contributor:not(:last-child) {
  margin-bottom: 5px;
}

.newsArticle .bylineContent .contributor cite:before {
  content: '';
}

.newsArticle .bylineContent .date {
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .newsArticle .bylineContent .date {
    margin-top: 0;
  }
}

.newsArticle .content {
  clear: both;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1024px) {
  .newsArticle .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.newsArticle .body {
  font-size: 1.125rem;
  letter-spacing: 0.013rem;
  line-height: 1.33;
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .newsArticle .body {
    margin-right: 2.15rem;
  }
}

.newsArticle--isSerialChapter {
  padding-top: 3.875rem;
}

.newsArticle__info {
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .newsArticle__info {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .newsArticle__info {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle__info {
    width: 50rem;
  }
}

.newsArticle__contributorPortrait {
  margin: 0 auto 1.25rem;
  width: 250px;
}

@media only screen and (min-width: 768px) {
  .newsArticle__contributorPortrait {
    float: right;
  }
}

@media only screen and (min-width: 1024px) {
  .newsArticle__contributorPortrait {
    width: 280px;
  }
}

.newsArticle {
  margin-bottom: 3.125rem;
}

.newsArticle h1,
.newsArticle h2,
.newsArticle h3,
.newsArticle h4,
.newsArticle h5 {
  margin: 0.75rem 0;
  font-family: var(--font-family-display);
}

.newsArticle h1 {
  font-size: 40px;
  font-weight: 300;
  color: #e31b33;
  word-wrap: break-word;
}

@media only screen and (min-width: 768px) {
  .newsArticle h1 {
    font-size: 40px;
  }
}

.newsArticle h2 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.newsArticle h3 {
  margin-top: 2.5rem;
  font-family: var(--font-family-display);
  font-size: 1.375rem;
  font-weight: 400;
}

.newsArticle h3 + h4 {
  margin-top: 0.75rem;
}

.newsArticle h4 {
  margin: 2.5rem 0 0.75rem;
  font-family: var(--font-family-display);
  font-size: 1.125rem;
  font-weight: 400;
}

.newsArticle header {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 1024px) {
  .newsArticle header {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle header {
    width: 50rem;
  }
}

.newsArticle img,
.newsArticle .img {
  max-width: 100%;
}

.newsArticle strong {
  font-weight: 600;
}

.newsArticle figcaption {
  color: #5c5b5f;
  font-size: 0.875rem;
}

.newsArticle .leadParagraph {
  font-size: 1.375rem;
}

.newsArticle figure {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isLeft,
  .newsArticle .figure--isRight {
    clear: both;
    width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isLeft {
    float: left;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isRight {
    float: right;
    margin-left: 30px;
  }
}

.newsArticle li {
  margin: 1.25rem 0;
}

.newsArticle ol.alpha {
  list-style: lower-alpha;
}

.newsArticle ul.references {
  list-style: none;
  padding: 0;
}

.newsArticle ul.references li {
  margin-bottom: 0.75rem;
}

.newsArticle ul.references li * {
  font-size: 0.938rem;
  font-weight: 400;
}

.newsArticle blockquote {
  box-sizing: border-box;
}

.newsArticle .quote {
  position: relative;
  margin: 40px 0;
  padding: 30px 30px 30px 74px;
  border-left: 4px solid #fbcfd4;
  font-family: var(--font-family-display);
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isLeft,
  .newsArticle .quote--isRight {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isLeft {
    float: left;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isRight {
    float: right;
    margin-left: 30px;
  }
}

.newsArticle .quote p:first-child:before {
  position: absolute;
  left: 30px;
  display: block;
  content: '';
  background: url('../quote.svg') no-repeat;
  height: 1.6938rem;
  width: 2.2813rem;
}

.newsArticle .quote .cite {
  font-size: 0.938rem;
  letter-spacing: 0.5px;
}

.newsArticle .dispQuote {
  margin: 0 30px;
  font-family: var(--font-family-sans);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
}

@media only screen and (min-width: 768px) {
  .newsArticle .dispQuote {
    margin: 0 3.75rem;
  }
}

.newsArticle table {
  margin: 2.5rem 0;

  border-collapse: collapse;
  border-spacing: 0;
}

.newsArticle td,
.newsArticle th {
  padding: 1rem;
  border: 1px solid #979797;
}

.newsArticle td .sub,
.newsArticle td .sup,
.newsArticle th .sub,
.newsArticle th .sup {
  font-size: 0.75rem;
}

.newsArticle th {
  vertical-align: middle;
  text-align: left;
  background-color: #f1f2f2;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.newsArticle th em {
  font-style: normal;
}

.newsArticle td {
  font-size: 1rem;
  vertical-align: text-top;
}

.newsArticle .boxedText {
  padding: 0.625rem;
  margin: 2.5rem 0;
  margin-top: 0;
  flex: 0 0 18.75rem;
  border-top: 1px solid #c2c0c2;
  border-bottom: 1px solid #c2c0c2;
}

.newsArticle .withBorder {
  border-top: 1px solid #c2c0c2;
  border-bottom: 1px solid #c2c0c2;
}

.newsArticle .boxedText .title {
  font-family: var(--font-family-display);
  font-size: 1.375rem;
  font-weight: 400;
  letter-spacing: 0.0187rem;
}

.newsArticle .boxedText .boxsubtitle {
  font-weight: 600;
}

.newsArticle .facts {
  flex: 0 0 18.75rem;
}
