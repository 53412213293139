@import url(https://fonts.googleapis.com/css?family=Source+Sans+Pro:200,200i,300,300i,400,400i,600,600i,700,700i,900,900i);
@import url(https://fonts.googleapis.com/css?family=Source+Serif+Pro:400,600,700);
@import url(https://fonts.googleapis.com/css?family=PT+Sans:400,400i,700,700i);
body {
  margin: 0;
  padding: 0;
  font-family: sans-serif;
}

q {
    quotes: "«" "»" "‹" "›" "«" "»";
}

.SlatePopper {
  z-index: 1000;
  margin-bottom: 7px;
}

.SlatePopperArrow {
  position: absolute;
  width: 3em;
  height: 3em;

  /* "top" placement */
  left: 0;
  width: 3em;
  bottom: 0;
  height: 1em;
  margin-bottom: -0.9em;
}

.SlatePopperArrow::before {
  content: "";
  margin: auto;
  display: block;
  width: 0;
  height: 0;
  border-style: solid;

  /* "top" placement */
  border-width: 0.5em 0.5em 0 0.5em;
  border-color: #fff transparent transparent transparent;
}

.SlatePopperPaper {
  width: 700px;
}

.SlatePopperContent {
  padding: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.summary {
  display: flex;
}

.summary .img {
  margin-right: 1.875rem;
  max-width: 30%;
  min-width: 15%;
}

.summary .img img {
  width: auto;
  width: initial;
  max-width: 100%;
}

.summary p {
  font-size: 1.125rem;
}

.summary h3 {
  margin: 0.625rem 0 0.9375rem;
  font-family: 'Tuna';
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
}

.Dropzone {
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.ImageDropzone {
  position: relative;
}

.HoverDropzone,
.HoverDropzone:hover {
  display: inline-block;
}

.ImageHoverWrapper {
  position: absolute;
  display: block;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

img:hover + .ImageHoverWrapper,
.ImageHoverWrapper:hover {
  visibility: visible;
  opacity: 1;
}

.ImageHoverMenu {
  position: relative;
  width: 100%;
  height: 100%;
}

.ImageHoverUploadIcon {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -50px;
}

.ImageHoverElement {
  position: absolute;
}

.ImageHoverElementTopRight {
  right: 10px;
  top: 10px;
}

.ImageHoverElementBottomLeft {
  left: 10px;
  bottom: 13px;
}

.ImageHoverElementBottomRight {
  right: 10px;
  bottom: 13px;
}

.ImageHoverAttributes {
  display: flex;
  align-items: flex-end;
}

.ImageHoverFields {
  background: #eee;
  opacity: 0.8;
  margin-right: 10px;
  padding: 10px;
}

.HoverPlaceholder {
  display: none;
}

.SlateImagePlaceholder {
  text-align: center;
}

.SlateImagePlaceholderDropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ddd;
  padding: 20px;
}

.newsArticle,
.embeddedDocument {
  font-family: var(--font-family-sans);
  background-color: white;
  color: var(--charcoal-grey);
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
}

.newsArticle a,
.embeddedDocument a {
  color: var(--darkish-blue);
}

/* This is for matching "normalized" css in juridika embeddedDocument */
.embeddedDocument .leadParagraph {
  font-family: var(--font-family-display);
  font-size: 1.125rem;
  font-weight: 500;
  letter-spacing: 0.031rem;
}

/* This is for matching "normalized" css in juridika for newsArticle */
@media only screen and (min-width: 1024px) {
  .newsArticle .juridikaInsightHeader,
  .newsArticle .serialHeader {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle .juridikaInsightHeader,
  .newsArticle .serialHeader {
    width: 50rem;
  }
}

.newsArticle {
  max-width: 71.25rem;
}

.newsArticle .byline {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: 1rem;
  margin: 0 0 1rem;
  border-top: 0.0625rem solid #c2c0c2;
  border-bottom: 0.0625rem solid #c2c0c2;
  padding: 0.625rem 0;
  color: #5c5b5f;
}

.newsArticle .byline cite:before {
  content: '\A0';
}

.newsArticle .byline__contributorInfo {
  margin-right: 0.625rem;
}

.newsArticle .byline__contributorInfo a {
  text-decoration: none;
}

.newsArticle .byline__contributorInfo cite:before {
  content: '';
}

.newsArticle .byline__contributorInfo cite {
  font-style: normal;
}

.newsArticle .byline__publicationDate .icon-dividerDot {
  margin: 0 0.625rem 0 0;
  vertical-align: middle;
}

.newsArticle .icon-dividerDot {
  margin: 0 0.625rem;
  vertical-align: middle;
}

@media only screen and (min-width: 768px) {
  .newsArticle .bylineContent {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 100%;
  }
}

.newsArticle .bylineContent .contributors {
  margin: 0;
  padding: 0;
  list-style: none;
}

.newsArticle .bylineContent .contributor {
  margin: 0;
}

.newsArticle .bylineContent .contributor:not(:last-child) {
  margin-bottom: 5px;
}

.newsArticle .bylineContent .contributor cite:before {
  content: '';
}

.newsArticle .bylineContent .date {
  margin-top: 5px;
}

@media only screen and (min-width: 768px) {
  .newsArticle .bylineContent .date {
    margin-top: 0;
  }
}

.newsArticle .content {
  clear: both;
  margin-bottom: 1rem;
}

@media only screen and (min-width: 1024px) {
  .newsArticle .content {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }
}

.newsArticle .body {
  font-size: 1.125rem;
  letter-spacing: 0.013rem;
  line-height: 1.33;
  flex-grow: 0;
  flex-shrink: 1;
  position: relative;
  width: 100%;
}

@media only screen and (min-width: 1024px) {
  .newsArticle .body {
    margin-right: 2.15rem;
  }
}

.newsArticle--isSerialChapter {
  padding-top: 3.875rem;
}

.newsArticle__info {
  margin-top: 3.125rem;
  display: flex;
  flex-direction: column-reverse;
  align-items: flex-start;
}

@media only screen and (min-width: 768px) {
  .newsArticle__info {
    display: block;
  }
}

@media only screen and (min-width: 1024px) {
  .newsArticle__info {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle__info {
    width: 50rem;
  }
}

.newsArticle__contributorPortrait {
  margin: 0 auto 1.25rem;
  width: 250px;
}

@media only screen and (min-width: 768px) {
  .newsArticle__contributorPortrait {
    float: right;
  }
}

@media only screen and (min-width: 1024px) {
  .newsArticle__contributorPortrait {
    width: 280px;
  }
}

.newsArticle {
  margin-bottom: 3.125rem;
}

.newsArticle h1,
.newsArticle h2,
.newsArticle h3,
.newsArticle h4,
.newsArticle h5 {
  margin: 0.75rem 0;
  font-family: var(--font-family-display);
}

.newsArticle h1 {
  font-size: 40px;
  font-weight: 300;
  color: #e31b33;
  word-wrap: break-word;
}

@media only screen and (min-width: 768px) {
  .newsArticle h1 {
    font-size: 40px;
  }
}

.newsArticle h2 {
  font-size: 1.5rem;
  font-weight: 500;
  letter-spacing: 0.3px;
}

.newsArticle h3 {
  margin-top: 2.5rem;
  font-family: var(--font-family-display);
  font-size: 1.375rem;
  font-weight: 400;
}

.newsArticle h3 + h4 {
  margin-top: 0.75rem;
}

.newsArticle h4 {
  margin: 2.5rem 0 0.75rem;
  font-family: var(--font-family-display);
  font-size: 1.125rem;
  font-weight: 400;
}

.newsArticle header {
  margin-bottom: 2.5rem;
}

@media only screen and (min-width: 1024px) {
  .newsArticle header {
    width: 42.5rem;
  }
}

@media only screen and (min-width: 1440px) {
  .newsArticle header {
    width: 50rem;
  }
}

.newsArticle img,
.newsArticle .img {
  max-width: 100%;
}

.newsArticle strong {
  font-weight: 600;
}

.newsArticle figcaption {
  color: #5c5b5f;
  font-size: 0.875rem;
}

.newsArticle .leadParagraph {
  font-size: 1.375rem;
}

.newsArticle figure {
  margin: 0;
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isLeft,
  .newsArticle .figure--isRight {
    clear: both;
    width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isLeft {
    float: left;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .figure--isRight {
    float: right;
    margin-left: 30px;
  }
}

.newsArticle li {
  margin: 1.25rem 0;
}

.newsArticle ol.alpha {
  list-style: lower-alpha;
}

.newsArticle ul.references {
  list-style: none;
  padding: 0;
}

.newsArticle ul.references li {
  margin-bottom: 0.75rem;
}

.newsArticle ul.references li * {
  font-size: 0.938rem;
  font-weight: 400;
}

.newsArticle blockquote {
  box-sizing: border-box;
}

.newsArticle .quote {
  position: relative;
  margin: 40px 0;
  padding: 30px 30px 30px 74px;
  border-left: 4px solid #fbcfd4;
  font-family: var(--font-family-display);
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isLeft,
  .newsArticle .quote--isRight {
    width: 50%;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isLeft {
    float: left;
    margin-right: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .newsArticle .quote--isRight {
    float: right;
    margin-left: 30px;
  }
}

.newsArticle .quote p:first-child:before {
  position: absolute;
  left: 30px;
  display: block;
  content: '';
  background: url(/static/media/quote.b815b770.svg) no-repeat;
  height: 1.6938rem;
  width: 2.2813rem;
}

.newsArticle .quote .cite {
  font-size: 0.938rem;
  letter-spacing: 0.5px;
}

.newsArticle .dispQuote {
  margin: 0 30px;
  font-family: var(--font-family-sans);
  font-size: 1rem;
  font-weight: 600;
  letter-spacing: normal;
  line-height: 1.5;
}

@media only screen and (min-width: 768px) {
  .newsArticle .dispQuote {
    margin: 0 3.75rem;
  }
}

.newsArticle table {
  margin: 2.5rem 0;

  border-collapse: collapse;
  border-spacing: 0;
}

.newsArticle td,
.newsArticle th {
  padding: 1rem;
  border: 1px solid #979797;
}

.newsArticle td .sub,
.newsArticle td .sup,
.newsArticle th .sub,
.newsArticle th .sup {
  font-size: 0.75rem;
}

.newsArticle th {
  vertical-align: middle;
  text-align: left;
  background-color: #f1f2f2;
  font-size: 1rem;
  font-weight: 600;
  text-transform: capitalize;
}

.newsArticle th em {
  font-style: normal;
}

.newsArticle td {
  font-size: 1rem;
  vertical-align: text-top;
}

.newsArticle .boxedText {
  padding: 0.625rem;
  margin: 2.5rem 0;
  margin-top: 0;
  flex: 0 0 18.75rem;
  border-top: 1px solid #c2c0c2;
  border-bottom: 1px solid #c2c0c2;
}

.newsArticle .withBorder {
  border-top: 1px solid #c2c0c2;
  border-bottom: 1px solid #c2c0c2;
}

.newsArticle .boxedText .title {
  font-family: var(--font-family-display);
  font-size: 1.375rem;
  font-weight: 400;
  letter-spacing: 0.0187rem;
}

.newsArticle .boxedText .boxsubtitle {
  font-weight: 600;
}

.newsArticle .facts {
  flex: 0 0 18.75rem;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_Light.abca2d34.ttf) format("truetype");
  font-weight: 300;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_LightItalic.7533875a.ttf) format("truetype");
  font-weight: 300;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_Regular.ea1ff466.ttf) format("truetype");
  font-weight: 400;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_RegularItalic.8d96a854.ttf) format("truetype");
  font-weight: 400;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_Medium.5e7d4c33.ttf) format("truetype");
  font-weight: 500;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_MediumItalic.5bcc6994.ttf) format("truetype");
  font-weight: 500;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_Bold.be9daef3.ttf) format("truetype");
  font-weight: 700;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_BoldItalic.e8fbe817.ttf) format("truetype");
  font-weight: 700;
	font-style: italic;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_Heavy.ab6d467b.ttf) format("truetype");
  font-weight: 800;
	font-style: normal;
}

@font-face {
  font-family: "Tuna";
  src: url(/static/media/Tuna_HeavyItalic.bbc8755e.ttf) format("truetype");
  font-weight: 800;
	font-style: italic;
}

.recommendedPagesModule {
  padding: 0.625rem;
  margin: 2.5rem 0;
  margin-top: 0;
  flex: 0 0 18.75rem;
}

.recommendedPagesHeading {
  margin: 0 !important;
  margin-left: -0.5rem;
  padding-bottom: -4rem;
  padding: 0.5rem 0;
  border-top: 0.125rem solid #29282d;
  border-bottom: 0.0625rem solid #29282d;
  font-family: 'Tuna';
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
}

.recommendedPages {
  margin-top: -1.5rem;
}

.recommendedPagesList {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.recommendedPagesList > li {
  border-bottom: 0.0625rem solid #e6d4cc;
  padding: 1.5rem 0;
  margin: 0px;
  margin-top: 1.25rem;
  pointer-events: none;
}

ul.recommendedPagesList > li ~ li {
  margin-top: 0;
}

li.recommendedPagesListItem > h3 {
  margin: 0 0;
}

.recommendedPagesLink {
  cursor: pointer;
  text-decoration: none;
  font-family: Tuna;
  font-weight: 500;
  font-size: 1.125rem;
}

.expressionsTable th,
.expressionsTable td {
  padding: 4px;
}

.commentMeta ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.commentMeta li {
  padding: 0.625rem 0;
}

.commentMeta li:not(:last-child) {
  padding: 0.625rem 0;
  border-bottom: 0.0625rem solid #fff;
}

.commentMeta__statusIndicator {
  display: inline-block;
  width: 0.625rem;
  height: 0.625rem;
  border-radius: 50%;
}

.commentMeta__statusIndicator:not(:last-child) {
  margin-right: 0.1875rem;
}

.commentMeta__statusIndicator--isDraft {
  background: #f8e74e;
}

.commentMeta__statusIndicator--isPublished {
  background: #98f398;
}

.commentMeta__statusIndicator--isOld {
  background: #8a8a8a;
}

.toc {
  max-width: 29.0625rem;
  padding: 0.625rem 0.625rem 0 0.625rem;
  height: calc(100vh - 110px);
  background: #291547;
  color: #fff;
  overflow-y: hidden;
}

.toc__wrapper {
  height: 100%;
  overflow-y: hidden;
}


.toc__wrapper--isCollapsed {
  display: none;
}

.toc__toggleAsideAction {
  display: flex;
  justify-content: flex-end;
}

.toc__toggleActions {
  padding-bottom: 5px;
  margin: 5px 0;
  border-bottom: .0625rem solid #473760;
}

.toc__toggleAction {
  margin: 5px;
  padding: 3px 10px;
  color: #fff;
  background: transparent;
  border: 1px solid #67597c;
  border-radius: 3px;
  outline: none;
}

.toc__toggleAction:disabled {
  color: #999;
}

.toc__toggleAction:not(:last-child) {
  margin-right: 0.625rem;
}

.toc__list {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
}

.toc__list > .toc__item:last-child {
  padding-bottom: 150px;
}

.toc__heading {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toc__heading--isActiveFragment {
  background: #67597b;
}

.toc__heading--hasEmptyComment {
  background: rgba(255,227,69,.32941);
}

.toc__selectAction {
  margin-right: 0.625rem;
  flex: 10 1;
  padding: 0.625rem;
  text-align: left;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
}

.toc__toggleItemAction {
  flex: 1 1;
  padding: 0.3125rem 0.625rem;
  background: none;
  border: none;
  outline: none;
}

.toc__sublist {
  display: none;
  padding-left: 1.25rem;
  list-style: none;
}

.toc__sublist--isOpen {
  display: block;
}

.commentariesEdit {
}

.commentariesEdit__activeFragment {
  font-size: 1.125rem;
}

.commentariesEdit__activeFragment--isDraft,
.commentariesEdit__activeFragment--isPublished {
  padding: 0.625rem 0.3125rem;
  border-radius: 0.1875rem;
  color: #fff;
}

.commentariesEdit__infoHeader {
  flex-basis: 100%;
  display: flex;
  margin-bottom: 1.875rem;
}

.commentariesEdit__infoHeader--isCollapsed {
  display: none;
}

.commentariesEdit__notice {
  padding: 0.625rem;
  color: #fff;
}

.commentariesEdit__activeFragment--isPublished,
.commentariesEdit__notice--isPublished {
  background: #c3423f;
}

.commentariesEdit__activeFragment--isDraft,
.commentariesEdit__notice--isDraft {
  background: #6daedb;
}

.commentariesEdit__createAsCopyAction {
  display: inline-block;
  padding: 0.3125rem 0.5625rem;
  background: #fff;
  border: none;
  border-radius: 0.125rem;
  color: #9e2b25;
  font-weight: 700;
}

.commentariesEdit__wrapper {
  display: flex;
  flex-wrap: wrap;
}

.commentariesEdit__xopusIframe {
  border: none;
  height: calc(100vh - 6.25rem);
  background: #fff;
  flex: 2 1;
}

.commentariesEdit__asideRight {
  margin-left: 1.25rem;
  max-width: 25rem;
  height: calc(100vh - 13.125rem);
  padding: 0.625rem 0.625rem 5.625rem 0.625rem;
  background: rgba(103, 89, 123, 0.1);
  border-radius: 0.1875rem;
  overflow: hidden;
}

.commentariesEdit__versionsInfo .commentMeta__title {
  margin-bottom: 1.25rem;
  font-size: 0.875rem;
  font-weight: 600;
}

.commentariesEdit__versionsInfo .commentMeta__draft {
  margin-bottom: 1.875rem;
}

.commentariesEdit__versionsInfo {
  height: 100%;
  overflow: scroll;
}

.commentariesEdit__versionsInfo p {
  margin: 0.3125rem 0;
  font-size: 0.875rem;
}

.commentariesEdit__versionsInfo ul {
  list-style: none;
  padding: 0 0 5.625rem 0;
}

.commentariesEdit__versionsInfo li:not(:first-child) {
  padding: 0.625rem 0;
}

.commentariesEdit__versionsInfo li:first-child {
  padding-top: 0;
}

.fileDropzone__dropzone {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border-width: 2px;
  border-radius: 2px;
  border-color: #eeeeee;
  border-style: dashed;
  background-color: #fff;
  color: #bdbdbd;
  outline: none;
  transition: border .24s ease-in-out;
}

.fileDropzone__error {
  margin: 16px 16px -16px;
  padding: 20px;
  flex: 1 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgba(182, 24, 39, 0.5);
  border: 1px solid rgba(182, 24, 39, 0.5);
  border-radius: 2px;
  color: #fff;
}


.legal_relations_table {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    text-align: left;
  }
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }
}

.PublishDocumentButtons_publishButton__2AqCy {
  background-color: #009688 !important;
  color: white !important;
  width: 150px;
}

.PublishDocumentButtons_unpublishButton__2_ZHY {
  background-color: #e91e63 !important;
  color: white !important;
  width: 150px;
}

.PublishDocumentButtons_modal__1wNvA {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.PublishDocumentButtons_modalContent__2pth7 {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.PublishDocumentButtons_modalButtonWrapper__2Hwhk {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px
}

.PublishDocumentButtons_cancelButton__1_oXc {
  margin-right: 10px !important;
}
.SearchableSelect_selectWrapper__1AAqf {
  margin-top: 5px;
}
.CreateResourceStickerModal_helperText__1ZIYC {
  color: darkGrey;
}

.CreateResourceStickerModal_closeButton__U8sOY {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer !important;
}

.CreateResourceStickerModal_fragmentContent__LLAe6 {
  background-color: #fce4cc;
  padding: 1rem;
  max-height: 300px;
  overflow: auto;
  margin-top: 5px;
}

.CreateResourceStickerModal_modal__1MezX {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.CreateResourceStickerModal_modalContent__3SdJc {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
}

section:not(:first-of-type) {
  margin-top: 15px;
}

section:last-of-type {
  margin-bottom: 15px;
}


.CreateResourceStickerModal_saveButton__1zQTE {
  align-self: end;
}


.ErrorNotice_errorNotice__1IIse {
  display: flex;
  align-items: center;
  padding-right: 15px; 
  padding: 15px;
  margin-top: 5px;
  border: 2px solid red;
}

.ErrorNotice_errorNotice__1IIse p {
  margin: 0;
  margin-left: 5px;
}
button {
  border: none;
  background-color: #F5F5F5;
  padding: 2px 4px;
  border-radius: 2px;
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  display: inline-flex;
  align-items: baseline;
  grid-gap: 4px;
  gap: 4px;
  font-size: inherit;
  cursor: text;
}
.CreateLinkModal_helperText__1zs_E {
  color: darkGrey;
  margin-bottom: 5px;
}

.CreateLinkModal_closeButton__a5Pmq {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer !important;
}

.CreateLinkModal_section__xISVN {
  display: flex;
  flex-direction: column;
}

.CreateLinkModal_modal__1isTn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.CreateLinkModal_modalContent__36e3z {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
}

.CreateLinkModal_saveButton__3GOFp {
  align-self: end;
}


