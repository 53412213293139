.summary {
  display: flex;
}

.summary .img {
  margin-right: 1.875rem;
  max-width: 30%;
  min-width: 15%;
}

.summary .img img {
  width: auto;
  width: initial;
  max-width: 100%;
}

.summary p {
  font-size: 1.125rem;
}

.summary h3 {
  margin: 0.625rem 0 0.9375rem;
  font-family: 'Tuna';
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0;
}
