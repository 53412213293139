
.legal_relations_table {
  table {
    border-collapse: collapse;
    width: 100%;
  }
  th {
    text-align: left;
  }
  td, th {
    border: 1px solid #ddd;
    padding: 8px;
  }
}
