.helperText {
  color: darkGrey;
}

.closeButton {
  position: absolute !important;
  top: 5px;
  right: 5px;
  cursor: pointer !important;
}

.fragmentContent {
  background-color: #fce4cc;
  padding: 1rem;
  max-height: 300px;
  overflow: auto;
  margin-top: 5px;
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
}

.modalContent {
  background-color: white;
  padding: 20px;
  width: 600px;
  max-height: 700px;
  position: relative;
  display: flex;
  flex-direction: column;
}

section:not(:first-of-type) {
  margin-top: 15px;
}

section:last-of-type {
  margin-bottom: 15px;
}


.saveButton {
  align-self: end;
}

