.recommendedPagesModule {
  padding: 0.625rem;
  margin: 2.5rem 0;
  margin-top: 0;
  flex: 0 0 18.75rem;
}

.recommendedPagesHeading {
  margin: 0 !important;
  margin-left: -0.5rem;
  padding-bottom: -4rem;
  padding: 0.5rem 0;
  border-top: 0.125rem solid #29282d;
  border-bottom: 0.0625rem solid #29282d;
  font-family: 'Tuna';
  font-size: 1rem;
  font-weight: 500;
  letter-spacing: 0.08rem;
  text-transform: uppercase;
}

.recommendedPages {
  margin-top: -1.5rem;
}

.recommendedPagesList {
  margin: 0;
  padding: 0;
  list-style: none;
}

ul.recommendedPagesList > li {
  border-bottom: 0.0625rem solid #e6d4cc;
  padding: 1.5rem 0;
  margin: 0px;
  margin-top: 1.25rem;
  pointer-events: none;
}

ul.recommendedPagesList > li ~ li {
  margin-top: 0;
}

li.recommendedPagesListItem > h3 {
  margin: 0 0;
}

.recommendedPagesLink {
  cursor: pointer;
  text-decoration: none;
  font-family: Tuna;
  font-weight: 500;
  font-size: 1.125rem;
}
