.Dropzone {
  border-width: 2px;
  border-color: rgb(102, 102, 102);
  border-style: dashed;
  border-radius: 5px;
}

.ImageDropzone {
  position: relative;
}

.HoverDropzone,
.HoverDropzone:hover {
  display: inline-block;
}

.ImageHoverWrapper {
  position: absolute;
  display: block;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.3s linear;
}

img:hover + .ImageHoverWrapper,
.ImageHoverWrapper:hover {
  visibility: visible;
  opacity: 1;
}

.ImageHoverMenu {
  position: relative;
  width: 100%;
  height: 100%;
}

.ImageHoverUploadIcon {
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  margin-top: -50px;
  left: 50%;
  margin-left: -50px;
}

.ImageHoverElement {
  position: absolute;
}

.ImageHoverElementTopRight {
  right: 10px;
  top: 10px;
}

.ImageHoverElementBottomLeft {
  left: 10px;
  bottom: 13px;
}

.ImageHoverElementBottomRight {
  right: 10px;
  bottom: 13px;
}

.ImageHoverAttributes {
  display: flex;
  align-items: flex-end;
}

.ImageHoverFields {
  background: #eee;
  opacity: 0.8;
  margin-right: 10px;
  padding: 10px;
}

.HoverPlaceholder {
  display: none;
}

.SlateImagePlaceholder {
  text-align: center;
}

.SlateImagePlaceholderDropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #ddd;
  padding: 20px;
}
