.errorNotice {
  display: flex;
  align-items: center;
  padding-right: 15px; 
  padding: 15px;
  margin-top: 5px;
  border: 2px solid red;
}

.errorNotice p {
  margin: 0;
  margin-left: 5px;
}