.toc {
  max-width: 29.0625rem;
  padding: 0.625rem 0.625rem 0 0.625rem;
  height: calc(100vh - 110px);
  background: #291547;
  color: #fff;
  overflow-y: hidden;
}

.toc__wrapper {
  height: 100%;
  overflow-y: hidden;
}


.toc__wrapper--isCollapsed {
  display: none;
}

.toc__toggleAsideAction {
  display: flex;
  justify-content: flex-end;
}

.toc__toggleActions {
  padding-bottom: 5px;
  margin: 5px 0;
  border-bottom: .0625rem solid #473760;
}

.toc__toggleAction {
  margin: 5px;
  padding: 3px 10px;
  color: #fff;
  background: transparent;
  border: 1px solid #67597c;
  border-radius: 3px;
  outline: none;
}

.toc__toggleAction:disabled {
  color: #999;
}

.toc__toggleAction:not(:last-child) {
  margin-right: 0.625rem;
}

.toc__list {
  height: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  overflow-y: scroll;
}

.toc__list > .toc__item:last-child {
  padding-bottom: 150px;
}

.toc__heading {
  padding: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.toc__heading--isActiveFragment {
  background: #67597b;
}

.toc__heading--hasEmptyComment {
  background: rgba(255,227,69,.32941);
}

.toc__selectAction {
  margin-right: 0.625rem;
  flex: 10;
  padding: 0.625rem;
  text-align: left;
  background: none;
  border: none;
  outline: none;
  color: #fff;
  font-size: 18px;
}

.toc__toggleItemAction {
  flex: 1;
  padding: 0.3125rem 0.625rem;
  background: none;
  border: none;
  outline: none;
}

.toc__sublist {
  display: none;
  padding-left: 1.25rem;
  list-style: none;
}

.toc__sublist--isOpen {
  display: block;
}
